import React from 'react'
import { makeStyles } from '@mui/styles'

import Card from './Card'

import coppercables from '../assets/copper-cables.jpeg'
import copperbars from '../assets/copper-bar.jpg'
import aluminumAlloys from '../assets/aluminium_alloys.jpeg'
import ironWire from '../assets/ironWire.png'

const useStyles = makeStyles((theme) => ({
  productsCont: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 50,
    alignItems: 'center',
    position: 'relative',
    overflowX: 'hidden',
    backgroundColor: '#12b5e0',
    flexDirection: 'column',
  },
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },
  infoTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'white',
    animation: '$slideFromTop 1s ease-in',
  },
  '@keyframes slideFromTop': {
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  prodCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 50,
  },
}))

const Products = ({ reference }) => {
  const classes = useStyles()
  const products = [
    {
      image: coppercables,
      title: 'copper cable',
      Description: 'produce quality copper cables',
      color: '#f8931f',
    },
    {
      image: copperbars,
      title: 'copper bar',
      Description: 'produce quality copper bars',
      color: '#f8931f',
    },
    {
      image: aluminumAlloys,
      title: 'Aluminum',
      Description: 'Aluminum Alloys Production',
      color: '#5f5f5f',
    },
    {
      image: ironWire,
      title: 'Iron',
      Description: 'Iron Wire Production',
      color: '#5f5f5f',
    },
  ]
  return (
    <>
      <div className={classes.productsCont} ref={reference}>
        <div className={classes.infoTitle}>Our Products</div>
        <div className={classes.prodCont}>
          {products.map((pr, index) => (
            <Card content={pr} key={index} />
          ))}
        </div>
      </div>
      <div className={classes.br} />
    </>
  )
}

export default Products
