import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: 250,
    height: 280,
    backgroundColor: '#0e0e0e',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: 15,
    margin: '0px 10px',
    border: '3px solid white',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    filter: 'blur(0)',
  },
  overlay2: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '50%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'transform 0.3s ease',
    transform: 'translateY(100%)',
    color: '#fff',
    fontSize: 18,
    fontWeight: 500,
    zIndex: 15,
  },
  overlay1: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s ease',
    transform: 'translateY(100%)',
  },
  overlay3: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '40%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    transition: 'transform 0.5s ease 0.2s, height 0.5s ease 0.2s',
    transform: 'translateY(40%)',
    color: '#fff',
    fontSize: 18,
    fontWeight: 400,
    zIndex: 20,
  },
  overlay3Expanded: {
    height: '35%',
    transition: 'transform 0.5s ease, height 0.5s ease 0.2s',
  },
  title: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 500,
    zIndex: 15,
    display: 'block',
    transition: 'display 0.3s ease',
  },
  TextDescription: {
    margin: '10px 15px',
    display: 'none',
    transition: 'display 0.3s ease',
    opacity: 1.4,
    textAlign: 'start',
    alignSelf: 'start',
    width: '100%',
  },
  cardHover: {
    '& $image': {
      transform: 'scale(1.1)',
      filter: 'blur(2px)',
    },
    '& $overlay2': {
      transform: 'translateY(0)',
      transitionDuration: '0.275s',
    },
    '& $overlay1': {
      transform: 'translateY(0)',
      transitionDuration: '0.35s',
    },
    '& $overlay3': {
      transform: 'translateY(0)',
      transitionDuration: '0.2s',
    },
    '&$overlay3Expanded': {
      height: '40%',
    },
    '& $title': {
      display: 'none',
    },
    '& $TextDescription': {
      display: 'block',
    },
  },
}))

const Card = ({ content }) => {
  const classes = useStyles()
  // const content = {
  //   title: 'Business Strategy',
  //   Description:
  //     'Business strategy coaching drives growth and success by guiding individuals and teams in developing effective strategies and optimizing resources.',
  //   color: '#094074',
  // }
  const handleCardHover = (event) => {
    event.currentTarget.classList.add(classes.cardHover)
  }

  const handleCardLeave = (event) => {
    event.currentTarget.classList.remove(classes.cardHover)
  }

  return (
    <div
      className={classes.card}
      onMouseEnter={handleCardHover}
      onMouseLeave={handleCardLeave}
    >
      <img src={content.image} alt={content.title} className={classes.image} />
      <div
        className={classes.overlay2}
        style={{ backgroundColor: `${content.color}90` }}
      >
        <p className={classes.TextDescription}>{content.title}</p>
      </div>
      <div
        className={classes.overlay1}
        style={{ backgroundColor: `${content.color}60` }}
      ></div>
      <div
        className={`${classes.overlay3} ${classes.overlay3Expanded}`}
        style={{ backgroundColor: `${content.color}99` }}
      >
        <p className={classes.TextDescription}>{content.Description}</p>
        <p className={classes.title}>{content.title}</p>
      </div>
    </div>
  )
}

export default Card
