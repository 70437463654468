import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },
  partnersCont: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflowX: 'hidden',
    flexDirection: 'column',
  },
  partnersTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'black',
    zIndex: 1,
  },
  partnerNamesContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: 25,
  },
  partnerName: {
    userSelect: 'none',
    fontSize: 18,
    padding: '10px 20px',
    color: 'black',
    border: '3px solid #f8931f',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#f8931f',
      color: 'white',
    },
  },
  slickCont: {
    width: '100%',
  },
}))

const Partners = () => {
  const classes = useStyles()
  const partners = [
    'ENICAB',
    'EURL WITCH OUARGLA',
    'SARL ORAN CABLE ELECTRIQUE',
    'SAMINDUSTRIE',
    'EPE POVAL SPA',
    'ARAB METALS',
    'SARL TADO',
  ]

  return (
    <>
      <div className={classes.partnersCont}>
        <div className={classes.partnersTitle}>Our Partners</div>
        <div className={classes.partnerNamesContainer}>
          {partners.map((partner, index) => (
            <div className={classes.partnerName} key={index}>
              {partner}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.br} />
    </>
  )
}

export default Partners
