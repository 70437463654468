import React from 'react'
import { makeStyles } from '@mui/styles'
import collection from '../assets/collection.jpg'
import shredding from '../assets/shredding.jpg'
import sorting from '../assets/sorting.jpg'
import compaction from '../assets/compaction.jpg'

const useStyles = makeStyles((theme) => ({
  cardStepCont1: {
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: 15,
    border: '4px Solid #f8931f',
    borderBottomWidth: 8,
    width: 1020,
    height: 360,
    zIndex: 1,
    backgroundColor: 'white',
    [theme.breakpoints.down(1068)]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
    },
  },
  cardStepCont2: {
    display: 'flex',
    alignItems: 'stretch',
    borderRadius: 15,
    border: '4px Solid #f8931f',
    borderBottomWidth: 8,
    width: 1020,
    height: 360,
    flexDirection: 'row-reverse',
    backgroundColor: 'white',
    zIndex: 1,
    [theme.breakpoints.down(1068)]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
    },
  },
  image1: {
    width: '50%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 4,
    borderRightStyle: 'solid',
    borderRightColor: '#f8931f',
    [theme.breakpoints.down(1068)]: {
      borderRadius: 8,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
      width: '100%',
    },
  },
  image2: {
    width: '50%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    borderLeftColor: '#f8931f',
    [theme.breakpoints.down(1068)]: {
      borderRadius: 8,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
      width: '100%',
    },
  },
  infoCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '115px 10px',
    boxSizing: 'border-box',
    [theme.breakpoints.down(1068)]: {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: 35,
    fontWeight: 600,
    color: '#f8931f',
  },
  description: {
    fontSize: 18,
    fontWeight: 400,
  },
}))

const CardSteps = ({ index }) => {
  const classes = useStyles()
  const step = [
    {
      image: collection,
      title: 'Collection',
      description:
        'The recycling process for ferrous and non-ferrous metals begins with their collection from professionals and individuals',
    },
    {
      image: shredding,
      title: 'Waste Shredding',
      description:
        'The shredders allow the collection to be fragmented with a system of rotating blades and hammers distributed around an axis.',
    },
    {
      image: sorting,
      title: 'Sorting',
      description:
        'Once crushed, the waste is sorted. It is about extracting steels using their magnetic properties. Facilities consisting of overbands circulate the waste for an initial sorting which separates ferrous metals from ferrous and non-ferrous metals, thanks to a system of magnets. ',
    },
    {
      image: compaction,
      title: 'Compaction',
      description:
        'In order to be transported to industries for reuse, scrap metal must be conditioned so that it can be handled and occupy as little space as possible. They therefore pass through compactors which increase their density by pressing them in the form of packages called bricks or balls. ',
    },
  ]
  return (
    <div
      className={
        index % 2 === 0 ? classes.cardStepCont1 : classes.cardStepCont2
      }
    >
      <img
        src={step[index].image}
        alt={step[index].title}
        className={index % 2 === 0 ? classes.image1 : classes.image2}
      />
      <div className={classes.infoCont}>
        <div className={classes.title}>{step[index].title}</div>
        <div className={classes.description}>{step[index].description}</div>
      </div>
    </div>
  )
}

export default CardSteps
