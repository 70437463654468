import { useTheme, ThemeProvider } from '@mui/material'
import './App.css'
import React, { createRef } from 'react'
import AboutUs from './componenets/AboutUs'
import Intro from './componenets/Intro'
import Navbar from './componenets/Navbar'
import Services from './componenets/Services'
import Usage from './componenets/Usage'
import WorkSteps from './componenets/WorkSteps'
import Products from './componenets/Products'
import Footer from './componenets/Footer'
import Partners from './componenets/Partners'
import Certificates from './componenets/Certificates'

function App() {
  const theme = useTheme()
  const introRef = createRef()
  const aboutRef = createRef()
  const serviceRef = createRef()
  const usageRef = createRef()
  const workstepRef = createRef()
  const productsRef = createRef()

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar
          refs={{
            introRef: introRef,
            aboutRef: aboutRef,
            serviceRef: serviceRef,
            usageRef: usageRef,
            workstepRef: workstepRef,
            productsRef: productsRef,
          }}
        />
        <Intro reference={introRef} />
        <AboutUs reference={aboutRef} />
        <Services reference={serviceRef} />
        <Usage reference={usageRef} />
        <WorkSteps reference={workstepRef} />
        <Products reference={productsRef} />
        <Partners />
        <Certificates />
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default App
