import React from 'react'
import { makeStyles } from '@mui/styles'
import image from '../assets/services.jpg'
import Background from './Background'
import './css/card.css'
const useStyles = makeStyles((theme) => ({
  servicesCont: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 580,
    overflowX: 'hidden',
  },
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },

  infoCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    paddingLeft: 200,
    boxSizing: 'border-box',
    [theme.breakpoints.down(1287)]: {
      paddingLeft: 0,
    },
  },
  infoTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'black',
    animation: '$slideFromTop 1s ease-in',
    marginBottom: 45,
  },
  '@keyframes slideFromTop': {
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  imageContainer: {
    width: '20rem',
    height: '30rem',
    borderRadius: '0rem 20rem 20rem 0rem',
    objectFit: 'cover',
    objectPosition: '-95px',
    border: '4px Solid #f8931f',
    position: 'absolute',
    top: 50,
    left: -3,
    animation: '$slideFromLeft 1s ease-out',
    zIndex: 10,
    [theme.breakpoints.down(1287)]: {
      display: 'none',
    },
  },
  '@keyframes slideFromLeft': {
    '0%': {
      transform: 'translateX(-100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  wrapServices: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(985)]: {
      flexDirection: 'column',
      gap: 15,
      // width: '80%',
    },
  },
  cardserv: {
    [theme.breakpoints.down(985)]: {
      // flexDirection: 'column',
      width: '100% !important',
      borderRadius: 15,
    },
  },
}))
const Services = ({ reference }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.servicesCont} ref={reference}>
        <img
          id="service-image"
          src={image}
          alt="services"
          className={classes.imageContainer}
        />
        <div className={classes.infoCont}>
          <div className={classes.infoTitle}>Our Services</div>

          <div className={classes.wrapServices}>
            <div
              className={`ag-courses_item ${classes.cardserv}`}
              style={{ width: 'max-content' }}
            >
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">
                  Recovery of ferrous and non-ferrous metals
                </div>
              </div>
            </div>

            <div
              className={`ag-courses_item ${classes.cardserv}`}
              style={{ width: 'max-content' }}
            >
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">
                  Recycling of ferrous and non-ferrous metals
                </div>
              </div>
            </div>
          </div>
          <div
            className={`ag-courses_item ${classes.cardserv}`}
            style={{ width: 'max-content', alignSelf: 'center' }}
          >
            <div className="ag-courses-item_link">
              <div className="ag-courses-item_bg"></div>

              <div className="ag-courses-item_title">
                Recovery and recycling of plastic
              </div>
            </div>
          </div>
        </div>
        <Background />
      </div>
      <div className={classes.br} />
    </>
  )
}

export default Services
