import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import logo from '../assets/logo.png'
import { useSpring, animated } from 'react-spring'
import Hamburger from 'hamburger-react'

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'transparent',
    transition: 'padding 0.5s ease',
    boxSizing: 'border-box',
    paddingBottom: 0,
  },
  navbarScolled: {
    backgroundColor: 'white',
    padding: '10px !important',
    position: 'fixed',
    zIndex: 35,
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    height: 140,
    transition: 'width 0.5s, height 0.5s',
    [theme.breakpoints.down(1140)]: {
      height: 110,
    },
  },
  logoScrolled: {
    height: '50px !important',
  },
  element: {
    textDecoration: 'none',
    color: '#12b5e0',
    alignSelf: 'flex-start',
    fontWeight: 'bold',
    minWidth: 110,
    border: '1px solid #12b5e0',
    borderRadius: 6,
    padding: '10px 5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
    [theme.breakpoints.down(1140)]: {
      display: 'none',
    },
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down(1140)]: {
      display: 'block',
      position: 'absolute',
      left: 15,
      top: 10,
      color: '#12b5e0',
      fontSize: 30,
      fontWeight: 800,
      cursor: 'pointer',
      userSelect: 'none',
      zIndex: 10,
    },
  },
  menuDropdown: {
    display: 'none',
    position: 'absolute',
    top: 70,
    right: 0,
    width: '100%',
    padding: '10px 0',
    backgroundColor: '#12b5e0',
    zIndex: 10,
    paddingBottom: 0,
    [theme.breakpoints.down(1140)]: {
      display: 'block',
    },
  },
  dropdownItem: {
    display: 'block',

    textAlign: 'center',
    margin: '10px 0',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 500,
    fontSize: 18,
  },
  br: {
    width: '100%',
    height: 3,
    backgroundColor: 'white',
  },
}))

const Navbar = ({ refs }) => {
  const [scrolling, setScrolling] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }
  const menuAnimation = useSpring({
    opacity: isDropdownOpen ? 1 : 0,
    marginTop: isDropdownOpen ? 0 : -10,
    top: scrolling ? 70 : 165,
    display: isDropdownOpen ? 'block' : 'none',
  })
  const classes = useStyles()
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav
      className={`${classes.navbar} ${scrolling ? classes.navbarScolled : ''}`}
    >
      <div className={classes.menuButton} onClick={handleDropdownToggle}>
        <Hamburger direction="left" />
      </div>
      {/* Desktop */}
      <>
        <div
          className={classes.element}
          onClick={() => {
            refs.introRef.current.scrollIntoView({ behaviour: 'smooth' })
          }}
        >
          Intro
        </div>
        <div
          className={classes.element}
          onClick={() => {
            refs.aboutRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          }}
        >
          About Us
        </div>
        <div
          className={classes.element}
          onClick={() => {
            refs.serviceRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          }}
        >
          Services
        </div>
        <img
          className={`${classes.logo} ${scrolling ? classes.logoScrolled : ''}`}
          src={logo}
          alt="logo"
        />
        <div
          className={classes.element}
          onClick={() => {
            refs.usageRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
              offsetTop: -70,
            })
          }}
        >
          What We Use
        </div>
        <div
          className={classes.element}
          onClick={() => {
            refs.workstepRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
              offsetTop: -70,
            })
          }}
        >
          How It Works
        </div>
        <div
          className={classes.element}
          onClick={() => {
            refs.productsRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
              offsetTop: -70,
            })
          }}
        >
          Products
        </div>
      </>
      {/* mobile */}
      <animated.div className={classes.menuDropdown} style={menuAnimation}>
        <p
          className={classes.dropdownItem}
          onClick={() => {
            refs.introRef.current.scrollIntoView({ behaviour: 'smooth' })
          }}
        >
          Intro
        </p>
        <div className={classes.br} />
        <p
          className={classes.dropdownItem}
          onClick={() => {
            refs.aboutRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          }}
        >
          About Us
        </p>
        <div className={classes.br} />
        <p
          className={classes.dropdownItem}
          onClick={() => {
            refs.serviceRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          }}
        >
          Services
        </p>
        <div className={classes.br} />
        <p
          className={classes.dropdownItem}
          onClick={() => {
            refs.usageRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
              offsetTop: -70,
            })
          }}
        >
          What We Use
        </p>
        <div className={classes.br} />
        <p
          className={classes.dropdownItem}
          onClick={() => {
            refs.workstepRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
              offsetTop: -70,
            })
          }}
        >
          How It Works
        </p>
        <div className={classes.br} />
        <p
          className={classes.dropdownItem}
          onClick={() => {
            refs.productsRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
              offsetTop: -70,
            })
          }}
        >
          Products
        </p>
        <div className={classes.br} />
      </animated.div>
    </nav>
  )
}

export default Navbar
