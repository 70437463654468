import React from 'react'
import { makeStyles } from '@mui/styles'
import { FaSquareFacebook, FaYoutube } from 'react-icons/fa6'
import { FaLinkedin } from 'react-icons/fa'

import logo from '../assets/logoF.png'

const useStyles = makeStyles((theme) => ({
  footerCont: {
    width: '100%',

    boxSizing: 'border-box',
    padding: '60px 25px',
    // backgroundColor: '#f8931f',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down(1000)]: {
      gap: 20,
    },
  },
  aboutSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down(1068)]: {
      alignItems: 'flex-start',
    },
  },
  logo: {
    height: 150,
  },
  about: {
    fontSize: 18,
    color: 'black',
    fontWeight: 400,
    maxWidth: 450,
    [theme.breakpoints.down(1068)]: {
      textAlign: 'start',
    },
  },
  contactsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,
  },
  contactsTitle: {
    fontSize: 28,
    color: 'black',
    fontWeight: 600,
  },
  contactsText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'start',
  },
  contacts: {
    display: 'flex',
    maxWidth: 400,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
  },
  socialsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,
  },
  socialsTitle: {
    fontSize: 28,
    color: 'black',
    fontWeight: 600,
  },
  socialsText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'start',
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    cursor: 'pointer',
  },
  socials: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
    width: 250,
  },
}))

const Footer = () => {
  const classes = useStyles()
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  return (
    <div className={classes.footerCont}>
      <div className={classes.aboutSection}>
        <img src={logo} alt="logo" className={classes.logo} />
        <div className={classes.about}>
          recovery and recycling of ferrous and non-ferrous metals, plastic,
          cardboard, glass and used oils
        </div>
      </div>
      <div className={classes.contactsSection}>
        <div className={classes.contactsTitle}>Contact Us</div>
        <div className={classes.contacts}>
          <div className={classes.contactsText}>
            <b>Mr : Touati Hamad Abderrahmane</b>
          </div>
          <div className={classes.contactsText}>
            <b>Manager:</b> 👉🏻 sarl Touati Hamad Abderrahmane cable ➡ THAC ♻
            recovery and recycling of ferrous and non-ferrous metals, and
            plastic
          </div>
          <div
            className={classes.contactsText}
            style={{ cursor: 'pointer' }}
            onClick={() => openInNewTab('mailto:atouatihamad@gmail.com')}
          >
            <b>📧directeur:</b> atouatihamad@gmail.com
          </div>
          <div
            className={classes.contactsText}
            style={{ cursor: 'pointer' }}
            onClick={() => openInNewTab('mailto:thacrecyclage@gmail.com')}
          >
            <b>📧administration:</b> thacrecyclage@gmail.com
          </div>
        </div>
      </div>
      <div className={classes.socialsSection}>
        <div className={classes.socialsTitle}>Socials</div>
        <div className={classes.socials}>
          <div
            className={classes.socialsText}
            onClick={() =>
              openInNewTab('https://www.facebook.com/THAC2022?mibextid=kFxxJD')
            }
          >
            <FaSquareFacebook style={{ color: '#0866FF' }} /> <b>Facebook:</b>{' '}
            THAC
          </div>
          <div
            className={classes.socialsText}
            onClick={() =>
              openInNewTab('https://www.linkedin.com/company/thac')
            }
          >
            <FaLinkedin style={{ color: '#0A66C2' }} /> <b>LinkedIn:</b> THAC
          </div>
          <div
            className={classes.socialsText}
            onClick={() => openInNewTab('https://www.youtube.com/@thac')}
          >
            <FaYoutube style={{ color: '#FF0000' }} /> <b>Youtube:</b> Thac
            Recyclage
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
