import React from 'react'
import { makeStyles } from '@mui/styles'
import Background from './Background'
import { FaYoutube } from 'react-icons/fa6'

const useStyles = makeStyles((theme) => ({
  introCont: {
    height: 'calc(100vh - 160px)',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    padding: '40px 25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // gap: 20,
    position: 'relative',
    justifyContent: 'space-around',
    [theme.breakpoints.down(750)]: {
      height: 'auto',
      justifyContent: 'auto',
      gap: 20,
    },
  },
  welcome: {
    fontSize: 16,
    fontWeight: 300,
    letterSpacing: 10,
    animation: '$letterSpacingAnimation 1s infinite alternate',
    color: '#f8931f',
  },
  '@keyframes letterSpacingAnimation': {
    '0%': {
      letterSpacing: 10,
    },
    '100%': {
      letterSpacing: 0,
    },
  },
  description: {
    width: 700,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 400,
    zIndex: 5,
    [theme.breakpoints.down(710)]: {
      width: 500,
    },
    [theme.breakpoints.down(600)]: {
      width: 334,
    },
  },
  video: {
    width: '40%',
    maxWidth: 700,
    marginTop: 20,
    zIndex: 5,
    [theme.breakpoints.down(1140)]: {
      width: '90%',
    },
  },
  youtube: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 25,
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: 50,
    height: 50,
    borderRadius: '50%',
    position: 'fixed',
    bottom: 25,
    right: 20,
    cursor: 'pointer',
    transition:
      'width 0.5s ease-out, height 0.5s ease-in-out, background-color 0.5s ease-out, padding 0.5s ease-out, border-radius 0.5s ease-out',
    '&:hover': {
      backgroundColor: '#FF0000',
      borderRadius: 15,
      padding: '0px 15px',
      width: 'fit-content',
    },
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },

  iconYout: {
    fontSize: 25,
    color: '#FF0000',
  },

  textYout: {
    fontSize: 18,
    color: 'white',
    fontWeight: 500,
  },
  youtubeMob: {
    alignItems: 'center',
    zIndex: 25,
    justifyContent: 'center',
    backgroundColor: 'white',
    width: 50,
    height: 50,
    borderRadius: '50%',
    position: 'fixed',
    bottom: 25,
    right: 20,
    display: 'none',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
    },
  },
}))

const Intro = ({ reference }) => {
  const classes = useStyles()
  const [hovered, setHovered] = React.useState(false)
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  return (
    <div className={classes.introCont} ref={reference}>
      <span className={classes.welcome}>Welcome! &#128075;</span>
      <span className={classes.description}>
        We are a company that specialaizes in the recovery and recycling of
        ferrous and non-ferrous metals, plastic, cardboard and glass and used
        oils
      </span>
      <video className={classes.video} id="videoIntro" autoPlay loop controls>
        <source src="/assets/videos/THAC.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Background />
      <div
        className={classes.youtube}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => openInNewTab('https://youtu.be/PReB9J9cHY0')}
      >
        {!hovered ? (
          <FaYoutube className={classes.iconYout} />
        ) : (
          <div className={classes.textYout}>Watch Now</div>
        )}
      </div>
      <div
        className={classes.youtubeMob}
        onClick={() => openInNewTab('https://youtu.be/PReB9J9cHY0')}
      >
        <FaYoutube className={classes.iconYout} />
      </div>
    </div>
  )
}

export default Intro
