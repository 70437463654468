import React from 'react'
import recycle from '../assets/recycle.svg'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down(800)]: {
      display: 'none',
    },
  },
}))

const Background = () => {
  const classes = useStyles()
  return (
    <>
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '15deg',
          width: 150,
          top: 0,
          left: 500,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '15deg',
          width: 150,
          top: 30,
          left: 50,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '-15deg',
          width: 150,
          top: 300,
          left: 370,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '15deg',
          width: 150,
          top: 350,
          left: 100,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '15deg',
          width: 150,
          top: 30,
          right: 50,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '-15deg',
          width: 150,
          top: 150,
          right: 357,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <img
        className={classes.image}
        src={recycle}
        alt="recy"
        style={{
          userSelect: 'none',
          WebkitUserDrag: 'none',
          position: 'absolute',
          rotate: '15deg',
          width: 150,
          top: 350,
          right: 100,
          opacity: 0.2,
          zIndex: 0,
        }}
      />
    </>
  )
}

export default Background
