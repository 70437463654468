import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

// horizantal
import cert6 from '../assets/certificates/cert6.jpg'
import cert7 from '../assets/certificates/cert7.jpg'
import cert8 from '../assets/certificates/cert8.jpg'

// vertical
import cert1 from '../assets/certificates/cert1.jpg'
import cert2 from '../assets/certificates/cert2.jpg'
import cert5 from '../assets/certificates/cert5.jpg'

const useStyles = makeStyles((theme) => ({
  certificatesCont: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 50,
    alignItems: 'center',
    position: 'relative',
    overflowX: 'hidden',
    backgroundColor: '#12b5e0',
    flexDirection: 'column',
  },
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },
  infoTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'white',
    animation: '$slideFromTop 1s ease-in',
  },
  '@keyframes slideFromTop': {
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  certCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 50,
  },
  horizantalCert: {
    width: 250,
    height: 'auto',
    borderRadius: 12,
    border: '2px solid white',
    cursor: 'pointer',
  },
  verticalCert: {
    height: 300,
    width: 'auto',
    borderRadius: 12,
    border: '2px solid white',
    cursor: 'pointer',
  },
  enlargedImage: {
    width: '100%',
    height: 'auto',
    borderRadius: 12,
  },
}))

const Certificates = () => {
  const classes = useStyles()
  const vert = [cert1, cert2, cert5]
  const horiz = [cert6, cert7, cert8]

  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const handleOpenDialog = (image) => {
    setSelectedImage(image)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <div className={classes.certificatesCont}>
        <div className={classes.infoTitle}>Certificates</div>
        {/* horizantal certs */}
        <div className={classes.certCont}>
          {horiz.map((cert, index) => (
            <img
              src={cert}
              alt="thac certificate"
              key={index}
              className={classes.horizantalCert}
              onClick={() => handleOpenDialog(cert)}
            />
          ))}
        </div>
        {/* vertical certs */}
        <div className={classes.certCont}>
          {vert.map((cert, index) => (
            <img
              src={cert}
              alt="thac certificate"
              key={index}
              className={classes.verticalCert}
              onClick={() => handleOpenDialog(cert)}
            />
          ))}
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          <img
            src={selectedImage}
            alt="enlarged certificate"
            className={classes.enlargedImage}
          />
        </DialogContent>
      </Dialog>
      <div className={classes.br} />
    </>
  )
}

export default Certificates
