import React from 'react'
import { makeStyles } from '@mui/styles'
import qm from '../assets/questionMark.svg'
const useStyles = makeStyles((theme) => ({
  aboutUsCont: {
    backgroundColor: '#12b5e0',
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 50,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(560)]: {
      gap: 20,
    },
  },
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },
  questionMark: {
    height: 150,
    animation: '$rotationAnimation 1s infinite alternate',
    rotate: '-7deg',
    [theme.breakpoints.down(520)]: {
      display: 'none',
    },
  },
  '@keyframes rotationAnimation': {
    '0%': {
      rotate: '-7deg',
    },
    '100%': {
      rotate: '5deg',
    },
  },
  infoCont: {
    textAlign: 'initial',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    maxWidth: 500,
  },
  infoTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'white',
  },
  infoDesc: {
    fontSize: 18,
    fontWeight: 400,
    color: 'white',
  },
}))

const AboutUs = ({ reference }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.br} />
      <div className={classes.aboutUsCont} ref={reference}>
        <img src={qm} alt="qm" className={classes.questionMark} />
        <div className={classes.infoCont}>
          <div className={classes.infoTitle}>About Us</div>
          <div className={classes.infoDesc}>
            The emerging THAC Foundation is a certified environmental company
            located throughout the country and has the great honor of being
            distinguished in the collection, sorting and evaluation of special
            and hazardous waste.
            <br />
            We are happy to have you join in participating in the treaty and
            agreements with companies, communities, and the public and private
            sectors.
            <br />
            We provide our customers with all appropriate solutions to preserve
            the environment and manage waste (safety / prevention /
            cost-effectiveness).
            <br />
            The waste is collected, sorted, transported, valued and displayed in
            the market in the best possible condition.
            <br />
            <br />
            What makes us different is that:
            <ul>
              <li> We collect the trash from your homes </li>
              <li>We pay you for your trash</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classes.br} />
    </>
  )
}

export default AboutUs
