import React from 'react'
import { makeStyles } from '@mui/styles'
import CardSteps from './CardSteps'

const useStyles = makeStyles((theme) => ({
  workStepsCont: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflowX: 'hidden',
    flexDirection: 'column',
  },
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },

  infoCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: 60,
    paddingLeft: 200,
    boxSizing: 'border-box',
  },
  infoTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'black',
    zIndex: 1,
  },
}))

const WorkSteps = ({ reference }) => {
  const classes = useStyles()
  const steps = [0, 1, 2, 3]
  return (
    <>
      <div className={classes.workStepsCont} ref={reference}>
        <div className={classes.infoTitle}>How It Works?</div>
        {steps.map((s) => (
          <CardSteps index={s} key={s} />
        ))}
      </div>
      <div className={classes.br} />
    </>
  )
}

export default WorkSteps
