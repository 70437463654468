import React from 'react'
import { makeStyles } from '@mui/styles'
import { BsTools } from 'react-icons/bs'
const useStyles = makeStyles((theme) => ({
  usageCont: {
    backgroundColor: '#12b5e0',
    width: '100%',
    boxSizing: 'border-box',
    padding: '60px 25px',
    display: 'flex',
    gap: 100,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(560)]: {
      gap: 20,
    },
  },
  br: {
    width: '100%',
    height: 4,
    backgroundColor: '#f8931f',
  },
  tools: {
    fontSize: 90,
    animation: '$rotationAnimation 3s infinite',
    rotate: '0deg',
    color: 'white',
  },
  '@keyframes rotationAnimation': {
    '0%': {
      rotate: '-360deg',
    },
    '100%': {
      rotate: '0deg',
    },
  },
  infoCont: {
    // textAlign: 'initial',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: 500,
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 45,
    fontWeight: 600,
    color: 'white',
  },

  list: {
    fontSize: 25,
    color: 'white',
    fontWeight: 600,
    lineHeight: 2,
  },
  listItem: {
    textAlign: 'initial',
  },
}))

const Usage = ({ reference }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.usageCont} ref={reference}>
        <div className={classes.infoCont}>
          <span className={classes.sectionTitle}>What Do We Use?</span>
          <BsTools className={classes.tools} />
        </div>
        <ul className={classes.list}>
          <li className={classes.listItem}>copper</li>

          <li className={classes.listItem}>aluminium</li>

          <li className={classes.listItem}>iron and metal</li>

          <li className={classes.listItem}>Used Oils</li>

          <li className={classes.listItem}>Expired Batteries</li>

          <li className={classes.listItem}>Wood</li>

          <li className={classes.listItem}>Plastic</li>

          <li className={classes.listItem}>Carton</li>

          <li className={classes.listItem}>Papper</li>

          <li className={classes.listItem}>Glass</li>
        </ul>
      </div>
      <div className={classes.br} />
    </>
  )
}

export default Usage
